import LoadingScreen from 'components/LoadingScreen';
import useSettings from 'hooks/useSettings';
import DashboardLayoutV1 from 'layouts/layout-v1/DashboardLayout';
import LayoutV2 from 'layouts/layout-v2/LayoutV2';
import DashboardLayoutV3 from 'layouts/layout-v3/DashboardLayout';
import CompleteEmployeeProfilePage from 'pages/employees/data-request/data-compiled-by-employee';
import EmployeesTable from 'pages/employees/employees-table';
import AllEmployeesTable from 'pages/employees/findall-employees-table';
import EmployeesReport from 'pages/employees/report';
import NoAccessPage from 'pages/noAcessPage';
import SegmentTable from 'pages/segments/segment-table';
import SelectTenantPage from 'pages/selectTenantPage';
import ShiftAssignment from 'pages/shifts/shift-assignment';
import ShiftsCalendar from 'pages/shifts/shifts-calendar';
import ShiftsTable from 'pages/shifts/shifts-table';
import { type FC, lazy, type LazyExoticComponent, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { checkTokenRole } from 'utils/checkToken';
import EmployeeCommunications from 'pages/employees/communications';
import DefaultUserPage from 'pages/defaultUserPage';
import UniqueEmployee from 'pages/employees/unique-employee';
import Sections from 'pages/turnover/sections';
import TurnoverSummary from 'pages/turnover/turnoverSummary';
import PaymentMethods from 'pages/turnover/paymentMethods';
import UniqueEmployeeCalendar from 'pages/employees/unique-employee-calendar';

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const JobDetailsPage = Loadable(
  lazy(async () => await import('./pages/job-advertisements/job-details-page')),
);

const DashboardMainPage = Loadable(
  lazy(async () => await import('./pages/dashboards/dashboard-main-page')),
);

const Account = Loadable(
  lazy(async () => await import('./pages/accounts/account')),
);

const NewEmployee = Loadable(
  lazy(async () => await import('./pages/employees/new-employee')),
);
const EmployeeCalendar = Loadable(
  lazy(async () => await import('./pages/employees/calendar')),
);

const DepartmentsTable = Loadable(
  lazy(async () => await import('./pages/departments/departments-table')),
);
const EmployeesHierarchy = Loadable(
  lazy(async () => await import('./pages/departments/employees-hierarchy')),
);
const ContractsTable = Loadable(
  lazy(async () => await import('./pages/data-table/contracts-table')),
);
const PositionsTable = Loadable(
  lazy(async () => await import('./pages/departments/workPositions-table')),
);
const ExperienceLevelsTable = Loadable(
  lazy(async () => await import('./pages/data-table/experienceLevels-table')),
);
const BuildingsTable = Loadable(
  lazy(async () => await import('./pages/buildings/buildings-table')),
);
const RoomsTable = Loadable(
  lazy(async () => await import('./pages/buildings/rooms-table')),
);
const SeasonsTable = Loadable(
  lazy(async () => await import('./pages/seasons/seasons-table')),
);

const JobAdvertisementsTable = Loadable(
  lazy(async () => await import('./pages/job-advertisements/job-advertisements-table'))
);


const EventList = Loadable(
  lazy(async () => await import('./pages/dashboards/event-list'))
);
// authentication
const Login = Loadable(
  lazy(async () => await import('./pages/authentication/login')),
);
const Register = Loadable(
  lazy(async () => await import('./pages/authentication/register')),
);
const NewPassword = Loadable(
  lazy(async () => await import('./pages/authentication/new-password')),
);
const ForgetPassword = Loadable(
  lazy(async () => await import('./pages/authentication/forget-password')),
);
const ForgetPasswordV2 = Loadable(
  lazy(async () => await import('./pages/authentication/forget-password-v2')),
);

const AboutPage = Loadable(lazy(async () => await import('./pages/about')));
const ContactPage = Loadable(lazy(async () => await import('./pages/contact')));
const PricingPage = Loadable(
  lazy(async () => await import('./pages/pricing-v2')),
);
const PrivacyPage = Loadable(lazy(async () => await import('./pages/privacy')));

// 404/Error page
const ErrorPage = Loadable(lazy(async () => await import('./pages/404')));

// Coming Soon page
// const ComingSoonOverlay = Loadable(lazy(async () => await import('./pages/comingSoon')));

const ActiveLayout = () => {
  const { settings } = useSettings();

  switch (settings.activeLayout) {
    case 'layout1':
      return <DashboardLayoutV1 />;
    case 'layout2':
      return <LayoutV2 />;
    case 'layout3':
      return <DashboardLayoutV3 />;
    default:
      return <DashboardLayoutV1 />;
  }
};

export const routes = () => {
  return [
    ...authRoutes,
    ...jobsRoutes,
    {
      path: 'dashboard',
      element: (
        <div>
          <ActiveLayout />
          <Routes>
            <Route path="/" />
          </Routes>
        </div>),
      children: dashboardRoutes,
    },
    {
      path: 'employee',
      element: (
        <div>
          <ActiveLayout />
          <Routes>
            <Route path="/" />
          </Routes>
        </div>),
      children: employeesRoutes,
    },
    {
      path: '*',
      element: <ErrorPage />,
    },
  ];
};

let role;

try {
  role = checkTokenRole();
}

catch (e) {

}

const renderSwitch = (role: string | undefined) => {
  switch (role) {
    case 'DEPCHIEF': {
      return <Navigate to="/dashboard/shift-assignments" />;
    }
    case 'USER': {
      return <Navigate to="/employee/defaultUserPage" />;
    }
    default:
      return <Navigate to="/dashboard" />;
  }
}


export const authRoutes = [
  { path: '/', element: renderSwitch(role) },

  { path: 'login', element: <Login /> },
  { path: 'register', element: <Register /> },
  { path: 'new-password', element: <NewPassword /> },
  { path: 'forget-password', element: <ForgetPassword /> },
  { path: 'forget-password-v2', element: <ForgetPasswordV2 /> },
  { path: 'select-tenant', element: <SelectTenantPage /> },
  { path: 'no-access', element: <NoAccessPage /> },

  { path: 'about', element: <AboutPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'privacy', element: <PrivacyPage /> },

  { path: 'profile-completion', element: <CompleteEmployeeProfilePage /> },
];

const jobsRoutes = [
  { path: 'jobs/:company/:id', element: <JobDetailsPage /> },
];

const dashboardRoutes = [
  { path: '', element: <DashboardMainPage /> },

  { path: 'account', element: <Account /> },

  { path: 'employees/candidates', element: <EmployeesTable isSeasonal={false} isAll={true} showCandidate={true} /> },
  { path: '/dashboard/employees/', element: <EmployeesTable isSeasonal={false} isAll={true} showCandidate={false} /> },
  { path: '/dashboard/allemployees/', element: <AllEmployeesTable isSeasonal={false} isAll={false} /> },
  { path: 'employees/annualemployees', element: <EmployeesTable isSeasonal={false} isAll={false} showCandidate={false} /> },
  { path: 'employees/seasonalemployees', element: <EmployeesTable isSeasonal={true} isAll={false} showCandidate={false} /> },
  { path: 'employees/new', element: <NewEmployee /> },
  { path: 'employees/calendar/:openRequestsModal', element: <EmployeeCalendar /> },
  { path: 'employees/report', element: <EmployeesReport /> },
  { path: 'employees/communications', element: <EmployeeCommunications /> },

  { path: '/dashboard/expiration', element: <EventList /> },
  { path: 'departments', element: <DepartmentsTable /> },
  { path: 'segments', element: <SegmentTable /> },
  { path: 'positions', element: <PositionsTable /> },
  { path: 'employees-hierarchy', element: <EmployeesHierarchy /> },
  { path: 'contracts', element: <ContractsTable /> },
  { path: 'experience-levels', element: <ExperienceLevelsTable /> },
  { path: 'buildings', element: <BuildingsTable /> },
  { path: 'rooms', element: <RoomsTable /> },
  { path: 'seasons', element: <SeasonsTable /> },
  { path: 'shift-template', element: <ShiftsTable /> },
  { path: 'shift-template/calendar', element: <ShiftsCalendar /> },
  { path: 'shift-assignments', element: <ShiftAssignment /> },
  { path: 'job-advertisements', element: <JobAdvertisementsTable /> },
  { path: 'turnover/paymentMethods', element: <PaymentMethods /> },
  { path: 'turnover/sections', element: <Sections /> },
  { path: 'turnover/summary', element: <TurnoverSummary /> }
];

const employeesRoutes = [

  { path: '/employee/defaultUserPage', element: <DefaultUserPage />},
  { path: '/employee/info', element: <UniqueEmployee />},
  { path: '/employee/calendar', element: <UniqueEmployeeCalendar /> },
  { path: '/employees/chat', element: (<></>) },
  { path: '/employees/shifts', element: (<></>) },
]
