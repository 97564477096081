import AdminEcommerce from './AdminEcommerce';
import Dashboard from './Dashboard';
import Ecommerce from './Ecommerce';
import ElementHub from './ElementHub';
import Logout from './Logout';
import ProjectChart from './ProjectChart';
import Settings from './Settings';
import UserProfile from './UserProfile';
import DataTable from './DataTable';
import Accounts from './Accounts';
import Calender from './Calender';
import Chat from './Chat';
import Invoice from './Invoice';
import TodoList from './TodoList';
import Session from './Session';
import Pages from './Pages';
import Pricing from './Pricing';
import ArrowLeftToLine from './ArrowLeftToLine';
import MessagesDollar from './MessagesDollar';
import BadgeDollar from './BadgeDollar';
import PersonChalkboard from './PersonChalkboard';
import CommentsQuestionCheck from './CommentsQuestionCheck';
import PersonCircleCheck from './PersonCircleCheck';
import RectangleCirclePlus from './RectangleCirclePlus';
import DiagramProject from './DiagramProject';
import LayerGroup from './LayerGroup';
import FileCircleQuestion from './FileCircleQuestion';
import UserList from './UserList';
import MoneyIcon from 'icons/MoneyIcon';
import { EuroSymbol, RequestQuote, Store } from '@mui/icons-material';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AdminEcommerce,
  ArrowLeftToLine,
  CommentsQuestionCheck,
  Dashboard,
  DiagramProject,
  Ecommerce,
  ElementHub,
  FileCircleQuestion,
  Logout,
  ProjectChart,
  Settings,
  UserProfile,
  UserList,
  Accounts,
  Calender,
  Chat,
  EuroSymbol,
  Invoice,
  TodoList,
  Session,
  Store,
  Pages,
  Pricing,
  DataTable,
  LayerGroup,
  MessagesDollar,
  MoneyIcon,
  BadgeDollar,
  RectangleCirclePlus,
  RequestQuote,
  PersonChalkboard,
  PersonCircleCheck,
};
