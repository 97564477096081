import { Button, Grid, styled, useTheme } from "@mui/material";
import { PaymentMethodEntity, SectionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H3, Small } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { turnoverApi } from "api";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useSeason } from "contexts/SeasonContext";
import FlexBox from "components/flexbox/FlexBox";
import toast, { Toaster } from "react-hot-toast";
import CurrencyInput from "react-currency-input-field";

interface ModalProps {
    section?: SectionEntity;
    paymentMethods?: PaymentMethodEntity[];
    open: boolean;
    onClose: () => void;
    onBack?: () => void;
}

const WIDTH = 450;

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: WIDTH,
    maxHeight: 700,
    overflowY: "auto",

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddTurnoverModal: FC<ModalProps> = (
    {
        open,
        onClose,
        onBack,
        section,
        paymentMethods
    }
) => {

    const [date, setDate] = useState(new Date());
    const [isDateValid, setIsDateValid] = useState(true);
    const [items, setItems] = useState<Array<{ value: number, stringValue?: string, paymentMethod: PaymentMethodEntity }>>([]);
    const { seasonId } = useSeason();
    const theme = useTheme();
    const fetchData = async () => {
        if (section && paymentMethods) {
            let temp: Array<{ value: number, stringValue?: string, paymentMethod: PaymentMethodEntity }> = [];
            for (const paymentMethod of paymentMethods) {
                const turnoverItem = (await turnoverApi.findUnique(date.getTime(), section.id, paymentMethod.id, seasonId)).data
                const value = turnoverItem ? turnoverItem.value : 0.00;
                temp = temp.concat({
                    value,
                    stringValue: String(value),
                    paymentMethod
                })
            }
            setItems(temp);
        }
    }

    useEffect(() => {
        fetchData().then(() => {
            resetForm();
        })
    }, [date, section, paymentMethods])

    const fieldValidationSchema = Yup.array().of(
        Yup.object({
            value: Yup.number().required(t('value.notValid'))
        })
    ).required(t('value.notValid'));

    const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: items,
        enableReinitialize: true,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            if (section) {
                for (let i = 0; i < values.length; i++) {
                    if (values[i] !== items[i]) {
                        turnoverApi.findUnique(
                            date.getTime(),
                            section.id,
                            values[i].paymentMethod.id,
                            seasonId
                        ).then(({ data }) => {
                            if (data) {
                                turnoverApi.update(String(data.id), seasonId, { value: values[i].value }).then(() => {
                                    onClose();
                                    setDate(new Date());
                                }).catch(() => {
                                    toast.error(t('turnover.dateErrorText'), {
                                        duration: 5000
                                    });
                                })
                            } else {
                                turnoverApi.create(seasonId, {
                                    date: date.toISOString(),
                                    paymentMethodId: values[i].paymentMethod.id,
                                    paymentSectionId: section.id,
                                    value: values[i].value
                                }).then(() => {
                                    onClose();
                                    setDate(new Date());
                                }).catch(() => {
                                    toast.error(t('turnover.dateErrorText'), {
                                        duration: 5000
                                    });
                                })
                            }
                        })
                    }
                }
            }
        }
    })

    return (
        <StyledAppModal open={open} handleClose={() => { onClose(); setDate(new Date()) }} alignContent={"center"} alignItems={"center"}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <H2 mb={1}>{t('amount.add')} </H2>
            <H2 mb={1}>{section?.name} - {section?.taxPercentage}%</H2>
            <CalendarInput
                sx={{ marginBottom: 1 }}
                format='dd.MM.yyyy'
                value={date}
                onChange={(newValue) => {
                    if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                        setDate(newValue);
                        setIsDateValid(true);
                    }
                    else
                        setIsDateValid(false);
                }}
                slotProps={{
                    textField: {
                        helperText: !isDateValid && t('date.error'),
                        error: !isDateValid
                    },
                }}
            />
            <Grid container>
                {
                    values &&
                        values.length > 0 ? values.map((item, index) => {
                            return <Grid item padding={1} xs={12} md={6}>
                                <Grid container marginTop={2} marginBottom={3}>
                                    <Grid item xs={12}>
                                        <H3 textOverflow={'ellipsis'}>{item.paymentMethod.name}</H3>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Small>{item.paymentMethod.description || '\u00A0'}</Small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CurrencyInput
                                            style={{
                                                width: '100%',
                                                margin: 1,
                                                borderRadius: 4,
                                                position: "relative",
                                                backgroundColor: theme.palette.background.paper,
                                                border: "1px solid #ced4da",
                                                fontSize: 16,
                                                padding: "10px 12px",
                                                transition: theme.transitions.create(["border-color", "box-shadow"]),
                                                borderColor: theme.palette.primary.main,
                                                boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
                                            }}
                                            type="text"
                                            value={item.stringValue}
                                            decimalsLimit={2}
                                            onValueChange={(value, _name, values) => {
                                                setFieldValue(`[${index}].stringValue`, value);
                                                setFieldValue(`[${index}].value`, values?.float);
                                            }}
                                            intlConfig={{
                                                locale: 'de-DE',
                                                currency: 'EUR',
                                            }}
                                            step={1}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }) :
                        <></>
                }
                <>{!values || values.length === 0 && t('paymentMethod.empty')}</>

            </Grid>

            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                {
                    onBack && <Button fullWidth variant="contained" color="inherit" onClick={() => { onBack(); setDate(new Date()); }}>
                        {t('back')}
                    </Button>
                }

                <Button fullWidth variant="contained" color="error" onClick={() => { onClose(); setDate(new Date()) }}>
                    {t('cancel')}
                </Button>

                <Button fullWidth variant="contained" onClick={() => { handleSubmit() }}>
                    {t('save')}
                </Button>
            </FlexBox>
        </StyledAppModal >
    )
}

export default AddTurnoverModal;