import { Alert, Autocomplete, Badge, Box, Button, Card, Divider, Fab, Grid, IconButton, Snackbar, TextField, Typography, styled, useTheme } from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { type FC, useEffect, useState } from 'react';
import AvatarBadge from 'components/avatars/AvatarBadge';
import Calendar from '../../page-sections/employees/calendar/Calendar';
import { useLocation, useParams } from 'react-router-dom';
import { departmentsApi, employeesApi, calendarEntriesApi } from '../../api';
import { CalendarEntryEntity, CalendarEntryEntityTypeEnum, DepartmentEntity, EmployeeEntity } from '../../api/generated';
import { useTranslation } from 'react-i18next';
import defaultProfileImage from '../../assets/images/default-profile.png';
import AppAvatar from 'components/avatars/AppAvatar';
import { useSeason } from 'contexts/SeasonContext';
import { alpha } from '@mui/material/styles';
import CustomToast from 'components/CustomToast/customToast';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import { DepartmentAssignment } from './new-employee';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { H6 } from 'components/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getFullNameWithIdConditionally } from 'utils/utils';
import { checkTokenRole } from 'utils/checkToken';
import NotificationsIcon from 'icons/NotificationsIcon';
import PendingRequestsModal from 'page-sections/calendar/PendingRequestsModal';

export const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  marginTop: 55,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export const CoverPicWrapper = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  height: 125,
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  margin: 'auto',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'white',
  backgroundColor: theme.palette.background.default,
}));

export interface GlobalCursorState {
  cursor: any;
  label: string;
  type: CalendarEntryEntityTypeEnum | null;
  color: string;
}

const EmployeeCalendar: FC = () => {
  const { seasonId } = useSeason();
  const theme = useTheme();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [employeeId, setEmployeeId] = useState<number>();
  const [employee, setEmployee] = useState<EmployeeEntity>();
  const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
  // define the method to set eventCount from Calendar class
  const [eventCount, setEventCount] = useState<Record<string, number>>()
  const [displayAvatar, setDisplayAvatar] = useState<string>(defaultProfileImage);
  const [employees, setEmployees] = useState<EmployeeEntity[] | any>(undefined);
  const [showToast, setShowToast] = useState(false);
  const employeeContext = useEmployeeContext();
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeEntity[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPendingRequests, setOpenPendingRequests] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<CalendarEntryEntity[]>([])
  const { openRequestsModal } = useParams()

  const [globalCursor, setGlobalCursor] = useState<GlobalCursorState>({
    cursor: 'default',
    label: 'default',
    type: null,
    color: 'black'
  });
  const [selectedColor, setSelectedColor] = useState('');

  const fetchPendingRequests = async () => {
    const requests = (await calendarEntriesApi.findNotAccepted(seasonId)).data;
    setPendingRequests(requests);
  }

  const fetchData = async () => {
    let currentEmployeeId: number;
    if (state?.employeeId || employeeId || employeeContext.employeeId !== -1) {
      // if employeeId not defined state.employeeId must be defined and it's the first interaction with the page
      if (!employeeId) {
        if (state?.employeeId) {
          setEmployeeId(state?.employeeId);
          currentEmployeeId = state?.employeeId;
        } else {
          setEmployeeId(employeeContext.employeeId);
          currentEmployeeId = employeeContext.employeeId;
        }
      }
      else {
        currentEmployeeId = employeeId;
      }

      try {
        // try only if season is selected
        if (seasonId)
          await Promise.all([
            (async () => {
              // const { data } = await employeesApi.findOne(currentEmployeeId, { params: { seasonId } });
              const data = filteredEmployees.find(x => x.id === currentEmployeeId);
              setEmployee(data);
              data?.filePath ? setDisplayAvatar(data.filePath) : setDisplayAvatar(defaultProfileImage);
            })(),
          ]);
      }
      catch (error: any) {
        if (error.response?.status === 400) {
          setShowToast(true);
        }
        setEmployee(undefined);
      }

    }
  };


  useEffect(() => {
    fetchData();
  }, [employeeId]);

  useEffect(() => {

    const fetchAllEmployeesData = async () => {
      const role = checkTokenRole();
      const data = (role === 'DEPCHIEF' ? await employeesApi.findSubordinates(seasonId) : await employeesApi.findAll({
        params: {
          includeIsIncomplete: true,
          seasonId
        },
      })).data;
      setEmployees(data);
      setFilteredEmployees(data);
    }
    fetchAllEmployeesData();
  }, [seasonId]);

  const handleFiltersChange = (event: React.SyntheticEvent, value: string[]) => {
    const filteredEmployees = value.length > 0 ? employees.filter((employee: any) =>
      value.some((selectedDepartment) =>
        employee.employeesToDepartments.some(
          (assignment: DepartmentAssignment) => assignment.department.name === selectedDepartment
        )
      )
    )
      : employees;
    setFilteredEmployees(filteredEmployees);
  };

  useEffect(() => {
    const fetchAllEmployeesData = async () => {
      const role = checkTokenRole();
      const response = role === 'DEPCHIEF' ? await employeesApi.findSubordinates(seasonId) : await employeesApi.findAll({
        params: {
          seasonId
        }
      });
      const employeesData = response.data;
      setEmployees(employeesData);
    };
    fetchAllEmployeesData();
    fetchData();
  }, [seasonId]);

  useEffect(() => {
    const role = checkTokenRole();
    (role === 'DEPCHIEF' ? departmentsApi.findWhereIsBoss(seasonId) : departmentsApi.findAll()).then((data) => {
      setDepartments(data.data);
    })
  }, [])

  useEffect(() => {
    fetchPendingRequests()
  }, [])

  useEffect(() => {
    if (openRequestsModal === 'true' && pendingRequests.length > 0) {
      setOpenPendingRequests(true);
    }
  }, [pendingRequests])

  const handleChangeCursorClick = (item: GlobalCursorState) => {
    setGlobalCursor({
      cursor: item.cursor,
      label: item.label,
      type: item.type,
      color: item.color
    });
    setSelectedColor(item.color);
  };
  const items = [
    { label: t('employees.calendar.types.half_day'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.HalfDay, color: '#00eaff' },
    { label: t('employees.calendar.types.day_off'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.DayOff, color: theme.palette.warning.main },
    { label: t('employees.calendar.types.vacation'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.Vacation, color: theme.palette.success.main },
    { label: t('employees.calendar.types.ill'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.Ill, color: theme.palette.error.main },
    { label: t('employees.calendar.types.unpaid_leave'), cursor: 'pointer', type: CalendarEntryEntityTypeEnum.UnpaidLeave, color: '#6c25be' },
  ];

  useEffect(() => {
    document.body.style.cursor = globalCursor.cursor;
    // Clean the cursor when the component is disassembled
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [globalCursor]);

  const handleToastClose = () => {
    setShowToast(false);
  };
  const changeEmployee = async (direction: 'next' | 'previous') => {
    if (filteredEmployees && filteredEmployees.length > 0 && employeeId) {
      const employeeIds = filteredEmployees.map((employee) => employee.id);
      // The change function is only available after the employee's instance has been created and/or already existed
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentIndex = employeeIds.indexOf(employeeId);

      let nextIndex: number;
      if (direction === 'next') {
        nextIndex = (currentIndex + 1) % employeeIds.length;
      } else if (direction === 'previous') {
        nextIndex = (currentIndex - 1 + employeeIds.length) % employeeIds.length;
      } else {
        return;
      }
      setEmployeeId(employeeIds[nextIndex])
      fetchData();
    }
    else {
      setOpenSnackbar(true);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  return (
    <>
      <Box pt={2} pb={4}>
        {showToast && (
          <CustomToast
            message={(t('alerts.employeeNotFoundInSeason'))}
            onClose={handleToastClose}
            duration={3000}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={8000}
          onClose={handleCloseSnackbar}
        >
          <Alert severity='error' style={{ borderRadius: '10px' }}>
            {t('alerts.noEmployeesFoundForFilters')}
          </Alert>
        </Snackbar>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <Card sx={{ padding: 3, position: 'relative' }}>
              <CoverPicWrapper>
                <img
                  width='100%'
                  height='100%'
                  alt='Team Member'
                  src='/static/background/user-cover-pic.png'
                  style={{ objectFit: 'cover' }}
                />
              </CoverPicWrapper>

              <ContentWrapper>
                <FlexBox justifyContent='center'>
                  <AvatarBadge>
                    <ImageWrapper>
                      <AppAvatar src={displayAvatar} style={{ width: '100%', height: '100%' }} alt='Team Member' sizes='large' />
                    </ImageWrapper>
                  </AvatarBadge>
                </FlexBox>

                <Box mt={2}>
                  {employeeId &&
                    <Divider style={{ marginBottom: "1rem" }}>{t("account.basicInformation.selectEmployee")}</Divider>
                  }
                  <Autocomplete
                    id="employee-selector"
                    disableClearable
                    options={filteredEmployees ? [...getFullNameWithIdConditionally(filteredEmployees.sort((a: EmployeeEntity, b: EmployeeEntity) => {
                      if (a.lastName < b.lastName) return -1;
                      if (a.lastName > b.lastName) return 1;
                      if (a.firstName < b.firstName) return -1;
                      if (a.firstName > b.firstName) return 1;
                      return 0;
                    })), { "id": 0, "fullName": t("account.basicInformation.selectEmployee"), "showId": false }] : []}
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => <TextField {...params} label={t('dashboard.employees.expiringContracts.table.employee')} />}
                    onChange={(event, value) => {
                      const selectedEmployeeId = value.id;
                      setEmployeeId(selectedEmployeeId);
                      employeeContext.setEmployeeId(selectedEmployeeId)
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography component="span">
                          {option.fullName}
                          {option.showId && (
                            <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.6rem', color: 'gray' }}>
                              {' ID ' + option.id + ''}
                            </Typography>
                          )}
                        </Typography>
                      </li>
                    )}


                    isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                    /**
                     * The defualt value 'Unknown' is used to allow the component to load the data after it has fetched it with the asynchronous call
                    **/
                    value={employee ? { id: employee.id, fullName: employee.firstName + ' ' + employee.lastName, showId: false } : { id: 0, fullName: t('report.selectEmployee'), showId: false }}
                  />
                  {employeeId &&
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>

                      <IconButton onClick={() => { changeEmployee("previous"); }} size='small'>
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <H6 fontStyle={"italic"} alignContent={"center"}>{t("account.basicInformation.changeEmployee")}</H6>
                      <IconButton onClick={() => { changeEmployee("next"); }} size='small'>
                        <KeyboardArrowRightIcon />
                      </IconButton>  </div>}

                </Box>
              </ContentWrapper>
              {employeeId ?
                <>
                  <Divider style={{ marginTop: "1rem" }}>{t("account.basicInformation.filterEmployees")}</Divider>
                  <Autocomplete
                    multiple
                    id="filters"
                    size="small"
                    options={departments.map(value => value.name)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t("account.basicInformation.filterPlaceholder")} />
                    )}
                    onChange={handleFiltersChange}
                    style={{ marginTop: '1rem' }}
                  />
                </> : ''
              }
            </Card>
            <Box pt={2}>
              <Card sx={{ padding: 3, position: 'relative' }}>
                <Box
                  style={{
                    padding: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    marginBottom: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      {t('employees.calendar.types.work')}:
                    </Grid>
                    <Grid item xs={2}>
                      {eventCount ? eventCount.WORK : ""}
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
            <Box pt={2}>
              <Card sx={{ padding: 3, position: 'relative' }}>
                <FlexBox justifyContent={'center'} flexDirection={'column'}>
                  <span style={{ marginBottom: '8px' }}>{t('employees.calendar.quickSelectionTool')}</span>
                  {items.map((item) => (
                    <Box
                      key={item.label}
                      onClick={() => { handleChangeCursorClick(item) }}
                      style={{
                        cursor: 'pointer',
                        padding: '8px',
                        border: `1px solid ${item.color}`,
                        marginBottom: '8px',
                        borderRadius: '4px',
                        backgroundColor: selectedColor === item.color ? alpha(item.color, 0.2) : 'transparent',
                        transition: 'background-color 0.5s'
                      }}
                    >
                      <Grid container>
                        <Grid item xs={10}>
                          {item.label}:
                        </Grid>
                        <Grid item xs={2}>
                          {eventCount ? eventCount[item.type] : ""}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                  {globalCursor.cursor !== 'default' && (
                    <Button
                      onClick={() => {
                        setGlobalCursor({
                          cursor: 'default',
                          label: 'default',
                          type: null,
                          color: 'black'
                        });
                        setSelectedColor('');
                      }}
                      variant='contained'
                      color='primary'
                    >
                      {t('employees.calendar.entry.resetTool')}
                    </Button>
                  )}

                </FlexBox>
              </Card>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            {employee && (
              <Calendar employeeId={employee.id} cursorType={globalCursor} setEventCount={setEventCount} userMode={false} />
            )}
          </Grid>
        </Grid>
        {
          pendingRequests.length > 0 &&
          <Fab
            variant='extended'
            color='primary' 
            disabled={pendingRequests.length === 0} 
            onClick={() => { setOpenPendingRequests(true) }}
            style={{position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3)}}>
            <Badge badgeContent={pendingRequests.length} color="primary" sx={{ mr: 1 }}>
              <NotificationsIcon />
            </Badge>
            {t('employees.calendar.request')}
          </Fab>
        }
        {
          openPendingRequests &&
          <PendingRequestsModal open={openPendingRequests} onClose={() => { setOpenPendingRequests(false) }} fetchData={() => { fetchPendingRequests() }} requests={pendingRequests} />
        }
      </Box>
    </>
  );
};

export default EmployeeCalendar;
