import { FC, useEffect, useRef, useState } from "react";
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

interface EditableTableCellProps {
    value: number;
    onSave: (value: number) => void;
    setFocused: (value: boolean) => void;
}

const EditableAmountField: FC<EditableTableCellProps> = ({
    value,
    onSave,
    setFocused
}) => {

    
    const inputRef = useRef<any>();
    const [floatVal, setFloatVal] = useState<number | undefined | null>(value);
    const [val, setVal] = useState<string | undefined>(value.toFixed(2));
    let modified = false;

    useEffect(() => {
        reset();
    }, [value]);

    const handleOnValueChange: CurrencyInputProps['onValueChange'] = (value) => {
        setVal(value);
    };

    const reset = () => {
        setFloatVal(value);
        setVal(value.toFixed(2));
    }

    return (
        <CurrencyInput
            ref={inputRef}
            style={{
                border: "none",
                outline: "none",
                textAlign: "center",
                padding: 0,
                backgroundColor: "transparent",
                borderBottom: "none",
                width: "100%",
                height: "100%",
                fontWeight: 500,
                fontFamily: "Montserrat"
            }}
            type="text"
            value={val}
            decimalsLimit={2}
            onValueChange={(value, _name, values) => {
                handleOnValueChange(value);
                setFloatVal(values?.float);
            }}
            onFocus={() => { setFocused(true); }}
            onBlur={() => {
                if (floatVal && !modified) {
                    modified = true;
                    onSave(floatVal);
                }
                if (!modified) {
                    reset();
                }
                modified = false;
                setFocused(false);
            }}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    if (floatVal) {
                        onSave(floatVal);
                        modified = true;
                        if (inputRef.current) {
                            inputRef.current.blur();
                        }
                    } else {
                        if (inputRef.current) {
                            inputRef.current.blur();
                        }
                    }
                } else if (e.key === 'Escape') {
                    if (inputRef.current) {
                        inputRef.current.blur();
                    }
                }
            }}
            intlConfig={{
                locale: 'de-DE',
                currency: 'EUR',
            }}
            step={1}
        />
    );
};

export default EditableAmountField;
