import { Button, styled, useTheme } from "@mui/material";
import { SectionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, Small } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import * as Yup from 'yup';
import AppTextField from "components/input-fields/AppTextField";
import FlexBox from "components/flexbox/FlexBox";
import { useFormik } from "formik";
import { depositsApi, turnoverApi } from "api";
import { useSeason } from "contexts/SeasonContext";
import CurrencyInput from "react-currency-input-field";


interface ModalProps {
    section?: SectionEntity;
    open: boolean;
    onClose: () => void;
    onBack?: () => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    width: 450,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddDepositModal: FC<ModalProps> = (
    {
        open,
        onClose,
        onBack,
        section
    }
) => {
    const theme = useTheme()
    const { seasonId } = useSeason();
    const [turnoverItemsSum, setTurnoverItemsSum] = useState(0);
    const [depositSum, setDepositSum] = useState(0);

    const [date, setDate] = useState<Date | null>(new Date());

    const [floatVal, setFloatVal] = useState<number | undefined | null>(turnoverItemsSum - depositSum);
    const [val, setVal] = useState<string | undefined>(String(turnoverItemsSum - depositSum));

    const [description, setDesciption] = useState("");

    const fetchData = async () => {
        if (section) {
            const turnoverSum = (await turnoverApi.getDepositableSum(section.id, seasonId)).data
            setTurnoverItemsSum(turnoverSum);

            const depSum = (await depositsApi.getSum(section.id, seasonId)).data
            setDepositSum(depSum);
        }
    }

    useEffect(() => {
        setVal(String(turnoverItemsSum - depositSum));
        setFloatVal(turnoverItemsSum - depositSum);
    }, [turnoverItemsSum, depositSum])

    useEffect(() => {
        fetchData();
    }, [section])

    const fieldValidationSchema =
        Yup.object({
            floatVal: Yup.number().max(turnoverItemsSum - depositSum).required(t('value.notValid')),
            date: Yup.date().required(t('date.notValid'))
        }).required();


    const { values, handleSubmit, resetForm, errors, touched, setFieldValue } = useFormik({
        initialValues: {
            date,
            val,
            floatVal,
            description
        },
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            if (section && date && values.floatVal) {
                depositsApi.create(
                    section.id,
                    seasonId,
                    {
                        date: date.toISOString(),
                        description,
                        value: values.floatVal,
                        paymentSectionId: section.id
                    }
                )
                resetForm();
                onClose();
            }
        },
        enableReinitialize: true
    })

    return (
        <StyledAppModal open={open} handleClose={() => { onClose() }} alignContent={"center"} alignItems={"center"}>
            <H2 mb={1}>{t('deposit.add')} </H2>
            <H2 mb={1}>{section?.name} - {section?.taxPercentage}%</H2>
            <CurrencyInput
                style={{
                    width: '100%',
                    marginTop: 1,
                    borderRadius: 4,
                    position: "relative",
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${errors.floatVal ? theme.palette.error.main : "#ced4da"}`,
                    fontSize: 16,
                    padding: "10px 12px",
                    borderColor: errors.floatVal ? theme.palette.error.main : theme.palette.primary.main
                }}
            type="text"
            value={values.val}
            decimalsLimit={2}
            onValueChange={(value, _name, values) => {
                setFieldValue('val', value);
                setFieldValue('floatVal', values?.float);
            }}
            intlConfig={{
                locale: 'de-DE',
                currency: 'EUR',
            }}
            step={1}
            />
            
            <Small>{t('deposit.toBePaid') + (turnoverItemsSum - depositSum) + '€'}</Small>

            <CalendarInput
                sx={{ marginBottom: 2, marginTop: 2 }}
                format='dd.MM.yyyy'
                label={t('deposit.date')}
                value={values.date}
                onChange={(newValue) => {
                    if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                        setDate(newValue)
                    }
                    else
                        setDate(null);
                }}
                slotProps={{
                    textField: {
                        helperText: touched.date && errors.date && t('date.notValid'),
                        error: Boolean(touched.date && errors.date)
                    },
                }}
            />

            <AppTextField
                fullWidth
                placeholder={t('deposit.description')}
                inputProps={{
                    min: 0,
                    step: 0.01,
                    style: { textAlign: 'right' },
                }}
                label={t('deposit.description')}
                value={values.description}
                onChange={(e) => {
                    if (e) {
                        setDesciption(e.target.value);
                    }
                }}
            />

            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                {
                    onBack && <Button fullWidth variant="contained" color="inherit" onClick={() => { resetForm(); onBack() }}>
                        {t('back')}
                    </Button>

                }
                <Button fullWidth variant="contained" color="error" onClick={() => { resetForm(); onClose() }}>
                    {t('cancel')}
                </Button>

                <Button fullWidth variant="contained" onClick={() => { handleSubmit() }}>
                    {t('save')}
                </Button>
            </FlexBox>
        </StyledAppModal >
    )
}

export default AddDepositModal;